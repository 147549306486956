import type {PhoneNumber} from 'libphonenumber-js';
import {isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js';

export const formatNational = (
  phone: string,
  options: {nationalPrefix: boolean}
): string => {
  const parsedNumber: PhoneNumber = parsePhoneNumber(phone);

  const formatted: string = parsedNumber.formatNational();

  if (options.nationalPrefix)
    return `+${parsedNumber.countryCallingCode} ${formatted}`;

  return formatted;
};

/**
 * Given a phone number in a string format, will return the phone formatted in E.164
 * if the number was valid, blank string otherwise. Uses +1 as the default country code.
 *
 * @param phone the phone number to be formatted in E.164
 * @returns the number formatted in E.164
 *
 * @example
 * // returns "+16472234567"
 * formatE164("(647) 223-4567")
 *
 * @example
 * // returns ""
 * formatE164("647")
 */
export const formatE164 = (phone: string): string => {
  if (!phone || !isValidPhoneNumber(phone, 'US')) return '';

  const parsedNumber: PhoneNumber = parsePhoneNumber(phone, 'US');

  const formattedE164 = parsedNumber.isValid() ? parsedNumber.number : '';

  return formattedE164;
};
